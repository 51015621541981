"use strict";
// @ts-nocheck
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var react_1 = __importStar(require("react"));
var AutocompleteSelector = /** @class */ (function (_super) {
    __extends(AutocompleteSelector, _super);
    function AutocompleteSelector(props) {
        var _this = _super.call(this, props) || this;
        _this.updateOriginal = function (text) {
            var suggestions = _this.props.suggestions;
            var option = suggestions.filter(function (suggestion) { return suggestion[_this.state.displayFieldName].toLowerCase() === text.toLowerCase(); })[0];
            if (option !== undefined)
                _this.state.originalOnChange(option);
            else
                _this.state.originalOnChange(text);
        };
        _this.onChange = function (e) {
            var suggestions = _this.props.suggestions;
            var userInput = e.currentTarget.value;
            var filteredSuggestions = suggestions.filter(function (suggestion) {
                return suggestion[_this.state.displayFieldName].toLowerCase().indexOf(userInput.toLowerCase()) > -1;
            });
            _this.setState({
                activeSuggestion: 0,
                filteredSuggestions: filteredSuggestions,
                showSuggestions: true,
                userInput: e.currentTarget.value
            });
            _this.updateOriginal(userInput);
        };
        _this.onClick = function (e) {
            _this.setState({
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: e.currentTarget.innerText
            });
            _this.updateOriginal(e.currentTarget.innerText);
        };
        _this.onKeyDown = function (e) {
            var _a = _this.state, activeSuggestion = _a.activeSuggestion, filteredSuggestions = _a.filteredSuggestions;
            try {
                if (e.keyCode === 13) {
                    _this.setState({
                        activeSuggestion: 0,
                        showSuggestions: false,
                        userInput: filteredSuggestions[activeSuggestion][_this.state.displayFieldName]
                    });
                    _this.state.originalOnChange(filteredSuggestions[activeSuggestion]);
                }
                else if (e.keyCode === 38) {
                    if (activeSuggestion === 0) {
                        return;
                    }
                    _this.setState({ activeSuggestion: activeSuggestion - 1 });
                    _this.scrollTo();
                }
                // User pressed the down arrow, increment the index
                else if (e.keyCode === 40) {
                    if (activeSuggestion - 1 === filteredSuggestions.length) {
                        return;
                    }
                    _this.setState({ activeSuggestion: activeSuggestion + 1 });
                    _this.scrollTo();
                }
            }
            catch (e) { }
        };
        _this.scrollTo = function () {
            setTimeout(function () {
                try {
                    document.getElementsByClassName("suggestion-active")[0].scrollIntoView();
                }
                catch (e) { }
            }, 10);
        };
        _this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: props.value,
            placeholder: props.placeholder,
            originalOnChange: props.onChange,
            disabled: props.disabled,
            onBlur: props.onBlur,
            displayFieldName: props.displayFieldName
        };
        return _this;
    }
    AutocompleteSelector.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.disabled != this.props.disabled)
            this.setState({ disabled: this.props.disabled });
    };
    AutocompleteSelector.prototype.render = function () {
        var _this = this;
        var _a = this, onChange = _a.onChange, onClick = _a.onClick, onKeyDown = _a.onKeyDown, _b = _a.state, activeSuggestion = _b.activeSuggestion, filteredSuggestions = _b.filteredSuggestions, showSuggestions = _b.showSuggestions, userInput = _b.userInput;
        var suggestionsListComponent;
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (react_1["default"].createElement("ul", { className: "suggestions" }, filteredSuggestions.map(function (suggestion, index) {
                    var className;
                    // Flag the active suggestion with a class
                    if (index === activeSuggestion) {
                        className = "suggestion-active";
                    }
                    return (react_1["default"].createElement("li", { className: className, key: index, onClick: onClick }, suggestion[_this.state.displayFieldName]));
                })));
            }
            else {
                suggestionsListComponent = (
                //<div className="no-suggestions">
                //    <em>No suggestions available.</em>
                //</div>
                react_1["default"].createElement(react_1["default"].Fragment, null));
            }
        }
        return (react_1["default"].createElement(react_1.Fragment, null,
            react_1["default"].createElement("input", { placeholder: this.state.placeholder, className: "form-control no-outline rounded_input", type: "text", onChange: onChange, onKeyDown: onKeyDown, value: userInput, disabled: this.state.disabled, onBlur: this.state.onBlur }),
            suggestionsListComponent));
    };
    return AutocompleteSelector;
}(react_1.Component));
exports["default"] = AutocompleteSelector;
