"use strict";
exports.__esModule = true;
exports.ruleBlockType = exports.configurableFieldTypeEnum = exports.configurableFieldType = void 0;
exports.configurableFieldType = [
    { value: 0, name: 'Text' },
    { value: 1, name: 'Numeric' },
    { value: 2, name: 'Data' },
    { value: 3, name: 'Boolean' },
    { value: 4, name: 'Fisier' },
    { value: 5, name: 'Valoare din lista' },
    { value: 6, name: 'Obiect extern' },
    { value: 6, name: '⚠️ CustomModelExternalReference ⚠️' },
    { value: 8, name: 'Interval timp' },
];
var configurableFieldTypeEnum;
(function (configurableFieldTypeEnum) {
    configurableFieldTypeEnum[configurableFieldTypeEnum["Text"] = 0] = "Text";
    configurableFieldTypeEnum[configurableFieldTypeEnum["Numeric"] = 1] = "Numeric";
    configurableFieldTypeEnum[configurableFieldTypeEnum["Data"] = 2] = "Data";
    configurableFieldTypeEnum[configurableFieldTypeEnum["Boolean"] = 3] = "Boolean";
    configurableFieldTypeEnum[configurableFieldTypeEnum["Fisier"] = 4] = "Fisier";
    configurableFieldTypeEnum[configurableFieldTypeEnum["ValoareDinLista"] = 5] = "ValoareDinLista";
    configurableFieldTypeEnum[configurableFieldTypeEnum["ObiectExtern"] = 6] = "ObiectExtern";
    configurableFieldTypeEnum[configurableFieldTypeEnum["CustomModelExternalReference"] = 7] = "CustomModelExternalReference";
    configurableFieldTypeEnum[configurableFieldTypeEnum["Timespan"] = 8] = "Timespan";
})(configurableFieldTypeEnum = exports.configurableFieldTypeEnum || (exports.configurableFieldTypeEnum = {}));
var ruleBlockType;
(function (ruleBlockType) {
    ruleBlockType[ruleBlockType["ruleType"] = 0] = "ruleType";
    ruleBlockType[ruleBlockType["condition"] = 1] = "condition";
    ruleBlockType[ruleBlockType["setValue"] = 2] = "setValue";
})(ruleBlockType = exports.ruleBlockType || (exports.ruleBlockType = {}));
